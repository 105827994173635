import Analytics from '../bundles/ZisTop/utils/Analytics';
import analyticsParams from '../bundles/ZisTop/analyticsParams';

import getCookie from "../bundles/ZisTop/utils/getCookie";
import sendAnalyticsEvent from '../bundles/ZisTop/utils/sendAnalyticsEvent';
import delayedEvents from "../bundles/ZisTop/utils/delayedEvents.js";

window.dataLayer = window.dataLayer || [];
window.gtag = window.gtag || function gtag(){dataLayer.push(arguments)}

const analytics = new Analytics(Object.assign(analyticsParams, { gtagEventsMap: window.convids_map || {a: 'a'} }));

console.log('qwe');

if (typeof window !== 'undefined') {
  window.ui = {
    getCookie: getCookie,
    Event: sendAnalyticsEvent,
    delayedEvents: delayedEvents,
    analytics: analytics,
  };
}

